<template>
  <div class="assessment-notes">
    <TransitionGroup name="highlight-slide-in">
      <template
        v-for="note in assessment.notes"
        :key="note.id"
      >
        <AssessmentNote :note="note" />
      </template>
    </TransitionGroup>
    <p
      v-if="!assessment.notes?.length"
      class="muted is-italic"
    >
      {{ $gettext("No notes yet.") }}
    </p>

    <FormKit
      v-if="showForm"
      type="form"
      :submit-label="$gettext('Save')"
      :submit-attrs="{ inputClass: 'button is-primary' }"
      @submit="submitForm"
    >
      <FormKitSchema :schema="schema" />
    </FormKit>
    <button
      v-else
      class="button is-primary is-outlined"
      @click="showForm = true"
    >
      {{ $gettext("Add a new note") }}
      <IconAdd />
    </button>
  </div>
</template>
<script lang="ts" setup>
  import { ref } from "vue"

  import { getAssessmentNoteSchema } from "@/schemas/Common"
  import { addNoteToAssessment } from "@/services/Assessments"
  import { gettext } from "@/utils/Translation"
  import { handlePendingUploads } from "@/services/Upload"

  import IconAdd from "@/components/svg/IconAdd.vue"
  import AssessmentNote from "@/components/assessments/AssessmentNote.vue"

  const { $gettext } = gettext
  const showForm = ref(false)
  const schema = getAssessmentNoteSchema({
    label: $gettext("Add a new note"),
    required: true,
    rows: 3,
    autofocus: true,
  })
  const props = defineProps({
    assessment: {
      type: Object,
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
  })

  async function submitForm({ note }) {
    // Don't upload separately if the assessment itself hasn't
    // been uploaded. (The notes will just be part of that upload.)
    const markForUpload = Boolean(props.assessment.cmiId)
    await addNoteToAssessment(props.tableName, props.assessment, note, markForUpload)
    showForm.value = false

    if (markForUpload) {
      await handlePendingUploads()
        .catch(error => {
          const okErrors = [
            "LOGIN_REQUIRED", // Handle that some other time?
            "CONNECTIVITY_REQUIRED",
            "SERVER_DOWN",
          ]
          if (!okErrors.includes(error.name)) {
            console.log(error)
          }
        })
    }
  }
</script>
<style lang="scss">
  .assessment-notes {
    font-size: 85%;
    h3 {
      font-weight: bold;
    }
    .icon-add {
      stroke: #154262;
      max-width: 1.5em;
    }
  }
</style>
