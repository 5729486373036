<template>
  <article>
    <SiteHeader
      :site="site"
      :breadcrumb="{ label: ariaLabel }"
    />
    <PageTitleWithRefresh
      :super-title="pageTitle"
      :aria-label="ariaLabel"
      :title="$gettext('Due for assessment')"
      :refresh-function="getChildrenFromServer"
      :refresh-underway="refreshUnderway"
    />
    <section class="panel">
      <form class="panel-block">
        <div class="control is-flex">
          <input
            class="input mb-0"
            type="search"
            :placeholder="$gettext('Search')"
            :value="search"
            @input="handleSearch"
          />
        </div>
        <p class="muted listing-count">
          {{ $ngettext("Showing %{ number } child of %{ total }.", "Showing %{ number } children of %{ total }.", filteredChildren.length, { number: filteredChildren.length, total: children.length }) }}
        </p>
      </form>
      <template v-if="pageReady">
        <template v-if="filteredChildren.length">
          <router-link
            v-for="child in filteredChildren"
            :key="child.id"
            class="panel-block"
            :to="{ name: 'ChildConfirmation', params: { childId: child.id, assessmentType: routeParam } }"
          >
            <div class="listing columns is-mobile is-0 is-vcentered">
              <div class="column">
                <div
                  v-if="child.createdDuringTraining"
                  class="training-mode-label"
                >
                  {{ $gettext("Created During Training") }}
                </div>
                <strong>{{ child.fullName }}</strong>
                <br />
                Due: {{ child[dueDateField]?.toLocaleDateString() || "As soon as possible" }}
              </div>
              <div class="column is-2 right-arrow has-text-right">
                <IconRightArrow />
              </div>
            </div>
          </router-link>
        </template>
        <div
          v-else
          class="box"
        >
          <p class="muted is-italic">
            {{ $gettext("No children are due for assessment.") }}
          </p>
        </div>
      </template>
      <PageLoading
        v-else
        wrapper-classes="padded"
      />
    </section>
    <LastSynced :last-synced="lastSynced" />
  </article>
</template>
<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute } from "vue-router"

  import {
    getCachedChildrenForSite,
    updateChildrenFromServer,
  } from "@/services/Child"
  import { getSiteByCmiId } from "@/services/Site"
  import useServerRefresher from "@/composables/useServerRefresher"
  import {
    setCurrentViewContext,
    getLastUpdateInfo,
    areCachedDataStale,
    showFlashMessage,
    setStalenessNotificationTime,
  } from "@/utils/GlobalState"

  import SiteHeader from "@/components/sites/SiteHeader.vue"
  import PageTitleWithRefresh from "@/components/PageTitleWithRefresh.vue"
  import PageLoading from "@/components/PageLoading.vue"
  import LastSynced from "@/components/LastSynced.vue"
  import IconRightArrow from "@/components/svg/IconRightArrow.vue"
  import { gettext } from "@/utils/Translation"

  const props = defineProps({
    pageTitle: {
      type: String,
      required: true,
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    // Assessment type. One of "growth", "anemia", "feedingScreening", "feedingObservation", "earlyid", "bestPractice"
    type: {
      type: String,
      required: true,
    }
  })
  const { $gettext } = gettext
  let children = []
  const filteredChildren = ref([])
  const search = ref("")
  const site = ref(null)
  const pageReady = ref(false)
  const route = useRoute()
  const lastSynced = ref(undefined)
  const siteCmiId = parseInt(route.params.siteCmiId as string)
  const { refreshUnderway, serverRefresher } = useServerRefresher()
  const typeMap = {
    growth: { dueDate: "nextGrowthAssessmentDate", routeParam: "g" },
    earlyid: { dueDate: "nextEarlyidAssessmentDate", routeParam: "d" },
    anemia: { dueDate: "nextAnemiaAssessmentDate", routeParam: "a" },
    feedingScreening: { dueDate: "nextMealtimeAssessmentDate", routeParam: "f" },
    feedingObservation: { dueDate: "nextMealtimeAssessmentDate", routeParam: "o" },
    bestPractice: { dueDate: "nextBestPracticesAssessmentDate", routeParam: "b" },
  }
  const dueDateField = typeMap[props.type].dueDate
  const routeParam = typeMap[props.type].routeParam
  let staleNotificationQueryKey = ""

  async function getChildrenFromServer(wasManuallyTriggered = false) {
    await serverRefresher(updateChildrenFromServer, [siteCmiId], staleNotificationQueryKey, wasManuallyTriggered)
      .catch(error => {
        if (error.name === "CONNECTIVITY_REQUIRED") {
          const msg = $gettext("You do not appear to be online.")
          let details = ""
          if (lastSynced.value) {
            details = $gettext("The information shown here was last updated %{ date }.", { date: lastSynced.value })
          }
          else {
            details = $gettext("The information has never been updated from the server.")
          }
          showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
          setStalenessNotificationTime(staleNotificationQueryKey)
        }
        else {
          throw error
        }
      })
    await getLocalChildren()
  }

  async function getLocalChildren() {
    children = await getCachedChildrenForSite(siteCmiId, { due: props.type })
    search.value = ""
    filteredChildren.value = children
    lastSynced.value = await getLastUpdateInfo({ type: "siteChildren", localItemId: site.value.id })
  }

  async function getData() {
    site.value = await getSiteByCmiId(siteCmiId)
    setCurrentViewContext(site.value, "site")
    staleNotificationQueryKey = `siteChildren-${site.value.id}`
    if (await areCachedDataStale({ type: "siteChildren", localItemId: site.value.id })) {
      await getChildrenFromServer()
    }
    else {
      await getLocalChildren()
    }
    pageReady.value = true
  }

  function handleSearch(e) {
    search.value = e.target.value

    if (search.value.length) {
      const searchWords = search.value.toLowerCase().split(" ")
      filteredChildren.value = children.filter((child) => {
        if (child.cmi === search.value.trim()) return true

        const childWords = child.fullName.toLowerCase().split(" ")
        return searchWords.every((searchWord) =>
          childWords.some((childWord: string) => childWord.includes(searchWord)),
        )
      })
    }
    else {
      filteredChildren.value = children
    }
  }

  getData()
</script>
<style lang="scss" scoped>
  form.panel-block {
    display: block;
  }
  .panel-block > .listing.columns {
    width: 100%;
    margin-inline-start: 0;
    .column {
      padding-inline: 0;
    }
  }
  .listing-count {
    font-size: 75%;
    font-style: italic;
    margin: 0.5em 0 0;
  }
</style>
