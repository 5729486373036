<template>
  <article>
    <ChildHeader
      v-if="child"
      :child="child"
    />

    <h1 class="title">
      <slot name="title" />
    </h1>
    <template v-if="child && child.id">
      <FormKit
        type="form"
        :submit-label="$gettext('Save')"
        :submit-attrs="{ inputClass: 'button is-primary' }"
        @submit="submitForm"
      >
        <FormKitSchema :schema="schema" />
      </FormKit>
    </template>
  </article>
</template>
<script lang="ts" setup>
  import { ref } from "vue"
  import { useRouter, useRoute } from "vue-router"

  import { getChildById } from "@/services/Child"
  import { appendAssessmentNote } from "@/services/Assessments"
  import { getAssessmentNoteSchema } from "@/schemas/Common"
  import { setCurrentViewContext } from "@/utils/GlobalState"
  import { gettext } from "@/utils/Translation"

  import ChildHeader from "@/components/children/ChildHeader.vue"

  const { $gettext } = gettext
  const route = useRoute()
  const router = useRouter()
  const childId = parseInt(route.params.childId as string)
  const assessmentId = parseInt(route.params.assessmentId as string)

  const props = defineProps({
    getObjectFunction: {
      type: Function,
      required: true,
    },
    createOrReplaceObjectFunction: {
      type: Function,
      required: true,
    },
    nextUrlName: {
      type: String,
      required: true,
    },
    noteFieldLabel: {
      type: String,
      default: null,
    },
    noteFieldHelp: {
      type: String,
      default: "",
    },
  })

  const child = ref(null)
  const screening = ref(null)
  const schema = getAssessmentNoteSchema({
    label: props.noteFieldLabel || $gettext("Assessment notes"),
    help: props.noteFieldHelp,
  })

  async function getData() {
    child.value = await getChildById(childId)
    screening.value = await props.getObjectFunction(assessmentId)
    setCurrentViewContext(child.value, "child")
  }

  async function submitForm(data: { note: string }) {
    if (data.note?.trim()) {
      screening.value.notes = appendAssessmentNote(screening.value.notes, data.note.trim())
      await props.createOrReplaceObjectFunction(child.value, screening.value)
    }
    router.push({ name: props.nextUrlName, params: { childId, assessmentId } })
  }

  getData()
</script>
